* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Cairo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e0e0e !important;
}

.MuiPopper-root .MuiPickersLayout-root {
  direction: ltr !important;
}

.marker-label-custom{
  background-color:aliceblue;
  line-height: 1;
}